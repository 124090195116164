import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

export const setupDayjs = () => {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  dayjs.updateLocale("en", {
    relativeTime: {
      ...dayjs.Ls.en.relativeTime,
      s: "%d seconds",
      m: "%d seconds"
    }
  });
};
